.ant-form-item-label > label{
float: none;
}

.buttonModalEdit {
display: flex;
justify-content: center;
margin-top: 30px;
}

.subtitleEditModal {
font-weight: 600;
}

.formItemEditProductModal {
    margin-bottom: 10px;
}

.itemEditProductModal {
    padding-bottom: 20px;
}

.publishProductsModal {
    top: 50px;
    margin-bottom: 100px;
}

.contentItem {
    padding-right: 15px;
    font-size: 16px;
    color: #333333;
}

.itemTitle{
    font-weight: 600;
    font-size: 14px;
    padding-bottom: 4px;
}

.ant-form-vertical .ant-form-item-label {
    padding-bottom: 2px;
}