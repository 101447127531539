.form-style {
    font-family: 'Poppins';
}

.ant-select-selector {
    font-size: 12px;
}

.ant-select-arrow .anticon > svg {
    fill: #010C33;
}

.ant-form-item-label > label {
    color: #010C33;
}

.ant-form-item {
    margin-bottom: 0px;
}