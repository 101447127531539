.marketplaceImg {
  border: 1px solid #eee !important;
}

.ant-skeleton-image-svg {
  width: 35px !important;
}

.customMarketplaceCollapse .ant-collapse-expand-icon {
  height: 60px !important;
  display: flex !important;
  align-items: center !important;
}