.g2-tooltip-value {
    color: #010C33;
}

.g2-tooltip-name {
    color: #969696;
}

.g2-tooltip-list-item {
    color: #969696;
}