.container-products {
    background-color: #F9F9F9;
    padding-left: 50px;
    padding-right: 50px;
    margin-top: 60px;
}

.container-products .ant-table-thead > tr > th {
    border-bottom: 1px solid #C4C4C4;
}

.container-products .ant-table-container table {
    border: none;
}

.container-products .ant-table-tbody > tr > td {
    border: none;
}

.container-products .ant-table-thead > tr > th {
    background: none;
    font-size: 14px;
    font-weight: 400;
}

.container-products .ant-table-container table > thead > tr th {
    padding: 8px 8px;
    text-align: center;
}
.container-products .ant-table-container table > tbody > tr > td {
    padding: 8px 8px;
    text-align: center;
}

.container-products .ant-select-selector {
    border-radius: 8px !important; 
    background-color: #fff;
}

.container-products .ant-form-item-label > label {
    font-weight: 400 !important;
    font-size: 14px;
}

.pillBox {
    background-color: #CACACA;
    color: #fff;
    padding-top: 5px;
    padding-bottom: 5px;
    border-radius: 32px;
}

.pillBox.errorImport {
    background-color: #F25154;
}

.pillBox.completeImport {
    background-color: #33BA6A;
}

.pillBox.postingImport {
    background-color: #969696;
}

.pillBox.warningImport {
    background-color: #e18f0b;
}