.planInfoCard {
    border-radius: 8px;
    margin: 2px;
}

.planInfoCard .ant-card-body {
    border-radius: 8px;
    padding: 10px 15px;
}

.ant-btn.ant-btn-primary {
  background-color: #010c33;
  color: #00E5A6;
}

.ant-radio-checked .ant-radio-inner{
    border-color: #33BA6A;
  }
  
.ant-radio-checked .ant-radio-inner:after{
  background-color: #33BA6A;
}
  
.ant-radio:hover .ant-radio-inner {
  border-color: #33BA6A ;
}