.cardTableDashboard .ant-table-tbody > tr > td, .cardTableDashboard .ant-table-tbody > tr > th, .ant-table-thead > tr > th, .ant-table-tbody > tr > td {
  overflow-X: unset;
}

.table-pandl-container tr[data-row-key="header"] td:not(:first-child) {
  background-color: #010c33;
  color: white;
  font-weight: 600;
  border-bottom: 0.1px solid #010c33 !important;
}

/* Estilos para las filas principales */
.table-pandl-container .main-row,
.table-pandl-container .main-row td:first-child {
  font-weight: bold;
  border-top: 2px solid #010c33 !important;
  border-bottom: 1px solid #010c33 !important;
}

.table-pandl-container .percentage-row td:first-child {
  font-weight: bold;
}

/* Estilos para las filas de totales */
.table-pandl-container .total-row,
.table-pandl-container .total-row td {
  font-weight: 600;
  background-color: #f0f0f0;
}

/* Bordes superiores para las filas de keys principales */
.table-pandl-container .main-row,
.table-pandl-container .main-row td {
  font-weight: 600;
  border-top: 2px solid #010c33 !important;
  border-bottom: 1px solid #010c33 !important;
}

.table-pandl-container .percentage-row,
.table-pandl-container .percentage-row td {
  font-weight: 600;
}