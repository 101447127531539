.iconDisclaimer svg {
    fill: #0425D4 !important;
    height: 20px;
    width: 20px;
    margin-top: 5px;
    font-size: 30px;
}

.ant-list-item-meta-content {
    text-align: left;
}

.unsupportedCategoriesTitle {
    font-weight: 500;
    font-size: 24px;
    text-align: center;
    line-height: 24px;
    margin-top: 25px;
}

.unsupportedModalButtons {
    display: flex;
    justify-content: space-evenly;
}

.supportedCategoriesButton {
    width: 310px;
    height: 52px;
}