.marketing-table {
  margin-left: 20px;
}

.marketing-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-header
  > table
  > thead
  > tr
  > th,
.marketing-table
  .ant-table.ant-table-bordered
  > .ant-table-container
  > .ant-table-body
  > table
  > tbody
  > tr
  > td {
  border-right: 0;
}

.marketing-table .ant-table-cell {
  table-layout: auto;
  line-height: 27px;
  font-style: normal;
  letter-spacing: -0.02em;
}

.marketing-table .ant-table-thead > tr > th {
  font-weight: 400;
  font-size: 12px;
}

.marketing-table .ant-card {
  border-radius: 10px;
}

.content-div .ant-card {
  border-radius: 10px;
}

.marketing-tooltip {
  width: 190px;
}

.marketing-tooltip-icon {
  padding-left: 5px;
}

.marketing-tooltip-text {
  color: #828282;
}

.ant-btn.btn-conectar {
  background-color: #010c33;
  border-color: #010c33;
  color: white;
  width: 175px;
  height: auto;
  border-radius: 6px;
}

.ant-btn.btn-conectar:hover {
  background-color: #010c33;
  border-color: #00e5a6;
  color: #00e5a6;
  border-radius: 6px;
}
