.icon-order-size {
  font-size: 32px;
}
.content-padding {
  margin-top: 20px;
}

.container-restock .header .ant-card-body h1 {
  text-align: left;
  font-size: 1.8em;
  font-weight: 100;
  margin-top: 10;
}

.container-restock .header .ant-card-body h1 b {
  font-weight: bold;
}

.container-restock .header .ant-card-body span {
  text-align: left;
  width: 100%;
  display: block;
  font-weight: 400;
}

/* .container-restock{
    background-color: #e7e7e733;
    padding-top: 70px;
} */

.main-restock .ant-table-thead > tr > th > div {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: nowrap;
  align-content: center;
}

thead.ant-table-thead tr th {
  border-bottom: 1px solid #e9e9e9 !important;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  display: none;
}

.container-restock .ant-steps {
  width: 50%;
  margin: 0 auto;
}

.steps-shipment .ant-steps-item.ant-steps-item-finish .ant-steps-item-content {
  color: #05df9f !important;
  padding: 5px 10px;
  border-radius: 12px;
}

.steps-shipment .ant-steps-item.ant-steps-item-finish .ant-steps-item-title {
  color: #010c33 !important;
  font-weight: 500;
  padding: 0;
}

.ant-steps-item-title::after {
  left: 115%;
}

/* .ant-steps-item-container{
    text-align: center;
} */

.container-restock h2 {
  font-size: 1.3em;
  font-weight: 400;
  font-style: normal;
  background-color: #fafafa;
  padding: 5px;
  text-align: left;
  border-radius: 12px;
  margin-top: 2em;
  padding-right: 20px;
}

.main-restock .newOrder {
  text-align: right;
}

.main-restock .ant-input-group-addon:first-child {
  border-radius: 10px;
  background-color: #fff;
}

.main-restock .ant-input-group .ant-input-affix-wrapper:not(:first-child) {
  border-radius: 10px;
}

.main-restock .ant-table-thead > tr > th,
.main-restock .ant-table table,
.main-restock .ant-table-tbody > tr > td {
  text-align: center;
  border: 0;
  font-weight: 300;
  font-size: 1em;
  color: #1f294b;
}

.restockStatus {
  border-radius: 32px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #fff;
}

.restockStatus.Draft {
  background-color: #e0e0e0;
}

.restockStatus.Working {
  background-color: #010c33;
}

.restockStatus.ReadyToShip {
  background-color: #010c33;
}

.restockStatus.Shipped {
  background-color: #63b9f0;
}

.restockStatus.InTransit {
  background-color: #63b9f0;
}

.restockStatus.Delivered {
  background-color: #33ba6a;
}

.restockStatus.CheckedIn {
  background-color: #63b9f0;
}

.restockStatus.Receiving {
  background-color: #63b9f0;
}

.restockStatus.Closed {
  background-color: #33ba6a;
}

.restockStatus.Error {
  background-color: #f25154;
}

.restockStatus.Cancelled {
  background-color: #969696;
}

.restockStatus.Deleted {
  background-color: #969696;
}

.restockEnvio img {
  width: 100px;
}

.restockEnvioTitle {
  padding: 20px;
}

.RestockEnvioContenido {
  padding: 15px;
}

.RestockEnvioContenido ul {
  list-style: none;
  display: flex;
  justify-content: flex-start;
  flex-wrap: nowrap;
  flex-direction: row;
  align-items: center;
  width: 100%;
  font-weight: 400;
  color: #010c33;
  padding: 0;
}

.RestockEnvioContenido li {
  font-size: 16px;
  padding-right: 15px;
}

.RestockEnvioCouriers label {
  align-items: center;
  border: 1px solid #ececec;
  padding: 10px;
  border-radius: 4px;
  height: 50px;
  min-width: 80px;
}

.RestockEnvioCouriers span.ant-radio + * {
  padding-top: 8px;
}

.RestockEnvioCouriers .iconFedex {
  fill: rgb(24, 49, 83);
  color: rgb(24, 49, 83);
}

.RestockEnvioCouriers svg {
  font-size: 70px;
}

.restockStepOne .ant-col-8 > label {
  width: 100%;
}

.restockStepOne .ant-col-8 > label > div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 100%;
}

.restockSelectorState .text-align-right {
  padding: 5px 20px;
}

.restockSelectorState .text-align-right span {
  padding-left: 10px;
}

.restockSelectorState .text-align-right > div > div {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  /* align-content: flex-start; */
  justify-content: space-around;
  align-items: center;
}

.restockSelectorState {
  display: flex;
  flex-flow: row wrap;
  min-width: 0;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: flex-end;
}

@media only screen and (max-width: 1500px) {
  .container-restock .ant-steps {
    width: 80%;
  }
}

.createNewOrder .ant-select-selector {
  border-radius: 10px !important;
  background-color: #010c33 !important;
  color: #00e5a6 !important;
}

.createNewOrder .ant-select-selection-placeholder,
.createNewOrder .ant-select-selection-item {
  color: #00e5a6 !important;
  text-align: left;
  font-size: 1.2em;
}

.main-restock .createNewOrder .ant-select-arrow .anticon > svg {
  fill: #00e5a6;
}

.shipment-step-title {
  background: rgba(240, 240, 240, 1);
  border-radius: 8px;
  font-weight: 400;
  font-size: 16px;
  padding: 4px 16px;
}

.rowContent {
  margin-top: 20px;
  margin-bottom: 30px;
}

.restock-shipment-content {
  background: #e8e8e880;
  border-radius: 4px;
  font-weight: 500;
  padding: 10px 12px;
}

.floating-button {
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 9999;
  box-shadow: 4px 4px 6px rgba(0, 0, 0, 0.6);
}

.print-button {
  padding: 10px 32px;
  border: 1px solid #20303c;
  height: auto;
  border-radius: 8px;
  color: #333333;
}

.restock-pickup-date-info {
  background: rgba(133, 182, 255, 0.15);
  border-radius: 8px;
  padding: 4px 16px;
}

.restock-primary-btn {
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  height: auto;
  width: auto;
  border-radius: 8px;
  padding: 5px 24px;
  background: rgba(1, 12, 51, 1);
  border: 1px solid rgba(1, 12, 51, 1);
  color: rgba(0, 229, 166, 1);
}

.restock-primary-btn:hover {
  border: 1px solid rgba(0, 229, 166, 1);
  background: rgba(1, 12, 51, 1);
  color: rgba(0, 229, 166, 1);
}

.restock-card {
  padding-left: 50px;
  padding-right: 50px;
  padding-top: 20px;
}

.form-title {
  font-style: normal;
  color: rgba(100, 100, 100, 1);
  font-size: 14px;
}

.form-grid .ant-col-8,
.form-grid .ant-col-16 {
  max-width: 100%;
}

#outline-card {
  background-color: #f9f9f9;
  padding-bottom: 0;
  padding-top: 70px;
  padding-left: 20px;
  padding-right: 15px;
}

#shipsmart-specific-info {
  margin-top: 30px;
}

.tag-shipment-card {
  border-radius: 10px;
  color: #010c33;
  font-size: 12px;
  margin-left: 10px;
  font-weight: 500;
}

.restock-boxes-table-title {
  background: rgba(240, 240, 240, 1);
  border-radius: 8px;
  font-weight: 400;
  font-size: 14px;
  padding: 4px 16px;
  margin-bottom: 15px;
  margin-top: 30px;
}

.ant-table-row.ant-table-row-level-0.listing {
  background-color: rgba(237, 245, 255, 1);
}

.table-header-boxes-shipment-second-column {
  display: flex;
  justify-content: flex-end;
}

.nextButton {
  height: 32px;
  border-radius: 4px;
  border-color: #20303c;
  background-color: #20303c;
  color: #ffffff;
  margin-top: 20px;
}

.addRowTableButton {
  height: 32px;
  border-radius: 4px;
  border-color: #20303c;
  color: #20303c;
  margin-top: 20px;
}

.addRowTableButton .anticon > svg {
  fill: #20303c;
}

.pickupDiv {
  border: 1px solid #e5e5e5;
  padding: 15px;
}

.shipment-options-card .ant-card-body {
  padding: 12px;
  border-radius: 12px;
}

.shipment-options-card-tag {
  border-radius: 4px;
  color: rgba(32, 48, 60, 1);
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 8px;
}

.ant-typography.shipment-options-card-title {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: rgba(32, 48, 60, 1);
  margin-bottom: 0px;
}

.ant-typography.shipment-options-card-date {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: rgba(100, 100, 100, 1);
  margin-bottom: 0px;
  margin-top: 8px;
}

.shipment-options-card-priceTag {
  border-radius: 4px;
  border-color: rgba(229, 229, 229, 1);
  padding: 15px;
  margin-right: 0px;
  height: 100%;
}

.shipment-options-card-discountCost {
  font-weight: 500;
  font-size: 14px;
  color: rgba(100, 100, 100, 1);
  text-decoration: line-through;
  margin-bottom: 10px;
}

.shipment-options-card-cost {
  font-weight: 600;
  font-size: 16px;
  color: rgba(34, 34, 34, 1);
}
