.g2-tooltip {
    font-family: 'Poppins';
}

.g2-tooltip-title {
    font-weight: 600;
    color: #010C33;
}

.g2-tooltip-list .g2-tooltip-list-item {
    font-weight: 500;
    color: #828282;
}

.g2-tooltip-value{
    color: #010C33;
}

.g2-tooltip-value {
    float: none !important;
    margin-left: 0 !important;
}