.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: transparent;
}
.dropdownTopMenu.ant-menu-item-selected a {
    color: #000;
}

.dropdownTopMenu.ant-menu-item-selected a:hover {
    color: #1890ff;
}
