.content-div-plans {
  margin: 0;
  padding: 0;
  background-color: #f9f9f9;
}

.plans {
  padding-top: 75px;
  padding-bottom: 15px;
  max-width: 1160px;
  margin: auto;
}

.not-logged-in-plans {
  padding-top: 0;
}

.plans-section {
  width: 100%;
  text-align: left;
  margin-bottom: 15px;
  border-radius: 10px;
}

.plans h2 {
  color: #010c33;
  font-weight: 300;
  font-size: 1.2em;
  line-height: 1;
  text-align: start;
}

.plans .plan-title {
  font-weight: 600;
  font-size: 30px;
  line-height: 45px;
}

.plans .plan-card {
  margin-bottom: 15px;
  border-radius: 10px;
  height: max-content;
  text-align: center;
  font-size: 15px;
  border-width: 2px;
  background-color: #fafafa;
  font-style: italic;
}

.plans .plan-card.tech {
  max-width: 230px;
}

.plans .plan-card.kam {
  max-width: 360px;
}

.plans .plan-card .header {
  min-height: 107px;
}

.plans .plan-card:hover,
.plans .plan-card.subscribed {
  border-color: #00e5a6;
  background-color: #ffffff;
}

.plans .plan-card:hover .empty-background-button,
.plans .empty-background-button:hover {
  border-color: #00e5a6;
  background-color: #00e5a6;
}

.plans #subscribe-button-link,
.plans #subscribe-button-link:hover {
  text-decoration: none;
  font-weight: 500;
  font-size: 18px;
  color: #010c33;
}

.plans .plan-card .empty-background-button[disabled],
.plans #subscribe-button-link[disabled] {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}

.plans .plan-price {
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
}

.plans .plan-price-detail {
  font-size: 12px;
  line-height: 12px;
  font-weight: 500;
  color: #616163;
}

.plans .plan-benefit {
  line-height: 30px;
  font-weight: 400;
  font-size: 14px;
  justify-content: center;
}

.plan-benefit.bold {
  font-weight: 600;
}

.plan-benefit.big {
  font-size: 15px;
  line-height: normal;
}

.on-subscription-button,
.on-subscription-button:hover {
  border: 2px solid #00e5a6;
  background-color: #00e5a6;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 7px;
  width: 168px;
  height: 3em;
  margin: 16px auto;
  font-style: italic;
}

.plans .empty-background-button {
  border: 2px solid #010c33;
  border-radius: 7px;
  width: 168px;
  height: 3em;
  margin: 16px 5px;
}

.plans .empty-background-link {
  color: #010c33;
}

.plans-banner {
  min-height: 100px;
  background-color: #00e5a6;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
  padding-top: 10px;
  padding-bottom: 5px;
}

.plans-banner-text {
  font-size: 30px;
  font-style: italic;
  letter-spacing: 0em;
  text-align: center;
  font-weight: 400;
  margin: 0;
}

.plans-banner-text .bolder {
  font-weight: 700;
}

.plan-icon {
  display: flex;
  align-items: center;
  font-size: 25px;
  height: 60px;
  color: #33ba6a;
}

.plan-description {
  margin: 20px 30px;
}

.plan-description-title {
  font-weight: 500;
}

.plan-description-body {
  color: #969696;
  font-size: 15px;
  font-weight: 400;
}

.plans-price-detail {
  font-size: 15px;
  font-weight: 700;
  font-style: italic;
  text-align: right;
  width: 100%;
  margin: 20px 30px 0px 0px;
}

.plans-cards-row {
  margin: 40px 0px;
  justify-content: space-around;
}

.subscription-confirmation-modal .ant-modal-content {
  padding: 40px;
  min-width: 550px;
}

.subscription-confirmation-modal .subscription-confirmation-title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
}

.subscription-confirmation-modal .subscription-confirmation-description {
  color: #969696;
  font-style: normal;
  line-height: 20px;
  font-size: 16px;
}

.subscription-confirmation-modal .subscription-details {
  margin: 35px auto 10px;
}

.subscription-confirmation-modal .subscription-details .ant-card-head {
  border-bottom: 0 none;
  font-weight: bold;
}

.subscription-confirmation-modal .ant-modal-footer {
  border-top: 0 none;
}

.subscription-confirmation-modal .subscription-details .ant-card-body {
  font-size: 16px;
  line-height: 20px;
  padding: 10px 24px;
}

.subscription-confirmation-check-icon {
  font-size: 45px;
  margin: 0 auto 50px;
}

.subscription-confirmation-check-icon svg {
  fill: #49c662;
}

.subscription-confirmation-modal
  .subscription-details
  .subscription-detail-title {
  color: #969696;
}

.subscription-confirmation-modal
  .subscription-details
  .subscription-detail-value {
  font-weight: bold;
}

.subscription-confirmation-modal #btn-subscription-confirmation {
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  margin: 0;
}

.current-subscription {
  margin: 0px 30px 30px 32px;
}

.current-subscription .row {
  font-style: italic;
  height: 65px;
  color: #969696;
  padding: 0px 30px;
  margin: 16px 0;
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.current-subscription .big-item {
  font-size: 16px;
  font-weight: 600;
  padding: 0;
  color: #010c33;
}

.current-subscription .item {
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  color: #010c33;
}

.current-subscription .icon {
  margin-right: 10px;
}

.cancel-subscription {
  height: 35px;
  font-size: 15px;
  font-weight: 500;
  font-style: italic;
  margin-left: auto;
  margin-right: 0;
  margin-bottom: 16px;
}

.kam-popover .ant-popover-inner-content {
  width: 200px;
  text-align: center;
  font-weight: 500;
}

.plans-section .col {
  text-align: center;
}

/* Estilos para el banner de error */
.plans-banner-inactive {
  width: 100%;
  margin-bottom: 15px;
  padding: 15px 25px;
  border-radius: 10px;
  background: rgb(255, 235, 0, 1);
}

.error-banner {
  display: flex;
  background: rgb(255, 235, 0, 1);
}

.error-banner span {
  margin-left: 10px;
  font-size: 14px;
  font-weight: 400;
}

.plans-banner-inactive button {
  border-radius: 50%;
  height: 15px;
  width: 15px;
  background-color: transparent;
  border: none;
  color: rgb(77, 71, 0, 1);
  font-size: 16px;
  cursor: pointer;
}

#custom-icon-color svg {
  color: black;
  fill: black;
  font-size: 18px;
}
