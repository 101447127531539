.newDestinationModal .ant-modal-content {
  border-radius: 10px;
}

.titleNewDestinationModal {
  font-weight: 600;
  font-size: 20px;
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}

.ant-form-item-required {
  color: #010C33;
  font-weight: 400;
  font-size: 14px;
  font-style: normal;
}

.cancelButtonNewDestinationModal {
  height: 40px;
  border-radius: 4px;
  border-color: #20303C;
  color: #20303C;
}

.okButtonNewDestinationModal {
  height: 40px;
  border-radius: 4px;
  background-color: #20303C;
  border-color: #20303C;
  color: #FFFFFF;
}

.okButtonNewDestinationModal:hover, .okButtonNewDestinationModal:focus, .okButtonNewDestinationModal:active {
  height: 40px;
  border-radius: 4px;
  border-color: #20303C;
  background-color: #20303C;
  color: #FFFFFF;
}

.okButtonNewDestinationModal > span {
  color: #FFFFFF;
}

.addBoxIcon svg {
  fill: #FFFFFF;
}

.newDestinationModal .ant-select-selector {
  font-size: 14px;
}