.ant-typography h3, div.ant-typography-h3, div.ant-typography-h3>textarea, h3.ant-typography {
    color: #010C33;
}

.subtitlePages {
    color: #969696;
    font-style: italic;
}

.priceColumns {
    display: flex;
    justify-content: right;
}

.priceRow {
    width: 100%;
    color: #010C33;
    font-weight: 500;
}

.feeRow {
    width: 100%;
    color: #333333;
    font-weight: 500;
}

.strikedFee {
    text-decoration: line-through;
    color: #969696;
    font-size: 13px;
}

.marginColumn {
    display: flex;
    justify-content: right;
}

.marginRow {
    width: 100%;
    color: #010C33;
    font-size: 20px;
    font-weight: 600;
}

.productColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.productTitle {
    color: #010C33;
}

.productSubTitle {
    color: #969696;
    font-size: 12px;
}

.summaryTitle {
    color: #010C33;
    font-weight: 400;
}

.ant-list-item-meta-description {
    color: #010C33
}

.iconWarningDisclaimer.anticon svg {
    fill: #010C33 !important
}

.feeRow .ant-collapse > .ant-collapse-item > .ant-collapse-header {
    align-items: center;
}

.calculator-col {
    display: flex;
    justify-content: center;
    align-items: end;
}