.container-title {
    display: flex;
    align-content: flex-end;
    align-items: top;
    justify-content: space-between;
}

.ant-tooltip-inner {
    color: #828282; 
    background-color: #FFFFFF;
}

.ant-tooltip .ant-tooltip-placement-top .ant-tooltip-content, .ant-tooltip-arrow, .ant-tooltip-arrow-content {
    --antd-arrow-background-color: linear-gradient(to right bottom, rgb(255, 255, 255), rgb(255, 255, 255));
}

.ant-tooltip-inner {
    font-family: 'Poppins';
    font-weight: 400;
    font-size: 12px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 11px;
    padding-right: 11px;
}

.intro-totales .ant-card-body, .intro-totales-detalle .ant-card-body {
    padding: 10px 8px 10px 10px;
}