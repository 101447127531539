.my-seller-account-card {
    margin-top: 16px;
    border-color: #1AAE9F;
}

.col-align-rigth-margin-top {
    text-align: right;
    margin-top: 20px;
}

.text-divider {
    color: #C3CFD9;
    font-size: 12px;
}

.check-status-my-account {
    height: 10px;
    width: 10px;
    background-color: 'none';
    display: inline-block;
    float: right;
    margin-top: 14px;
}

.side-bar-menu {
    height: 100%;
    border-right: 0px;
    background-color: #F0F2F5;
}

.side-bar-icon-item {
    margin-right: 2px !important;
}

.tab-pane-marketplace {
    text-transform: capitalize;
}

.contentOptionsPhone {
    width: 140px !important;
}

.fontForm {
    font-family: "Poppins";
}

/*Input And Select My Account */
.formFieldMyAccound {
    padding: 6px 0px 6px 11px;
    height: 42px;
    background: #FFFFFF;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    margin-bottom: 30px;
}

.formFieldMyAccound.ant-select:not(.ant-select-customize-input)>.ant-select-selector {
    border: none;
}

.formFieldMyAccound.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select- customize-input)>.ant-select-selector {
    border: none;
    box-shadow: none;
    border-radius: 0px;
}


/* MARKETPLACES */

.contentColumnsMarketplaces {
    padding: 10px;
    background-color: #C4C4C433;
    color: #010C33;
    text-align: center;
    font-size: 15px;
    font-family: "Poppins";
    font-weight: 400;
    margin-left: 0px !important;
    margin-right: 0px !important;
}

.contentListMarketplaces {
    padding: 13px 10px;
    font-family: "Poppins";
    font-weight: 200;
    color: #010C33;
}

.contentItemsCenter {
    align-items: center;
    background-color: blue;
}

.space-align-container {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
}

.space-align-block {
    flex: none;
    margin: 27px -40px;
    padding: 4px;
    font-family: "Poppins";
    color: #010C33;
}

.space-align-block .mock-block {
    display: inline-block;
    padding: 32px 8px 16px;
    background: rgba(150, 150, 150, 0.2);
}

.marketplacesSelectAndBtnBase {
    padding: 6px 0px 6px 11px !important;
    height: 42px !important;
    border-radius: 8px;
    color: white !important;
    font-size: 14px;
    text-align: center !important;
    font-weight: 200;
}

.marketplacesSelectAndBtnActive {
    background: #33BA6A !important;
    text-align: center;
    font-size: 14px;
    border: none !important;
    width: 80% !important;
}

.marketplacesSelectAndBtnActive span {
    font-size: 14px;
    margin-right: 12px;
    border: none;
}

.marketplacesSelectAndBtnInactive {
    background: #969696 !important;
    width: 80% !important;
}

.marketplacesSelectAndBtnError {
    background: #F25154 !important;
}

.marketplacesSelectAndBtnToConnect {
    border: 1px solid #CACACA !important;
    background: #CACACA !important;
    color: #010C33 !important;
    font-size: 14px;
    text-align: center;
    width: 80% !important;
}

.marketplacesSelectAndBtnToConnect:hover {
    border: 1px solid #CACACA !important;
    background: #CACACA !important;
    color: #010C33 !important;
}

.marketplacesSelectAndBtnToConnect:focus {
    border: 1px solid #CACACA !important;
    background: #CACACA !important;
    color: #010C33 !important;
}

.marketplacesSelectAndBtnBaseToConnect {
    height: 42px !important;
    border-radius: 8px !important;
    font-size: 14px !important;
}

.marketplacesSelectAndBtnBaseToConnect:disabled {
    line-height: 32px !important;
}

.marketplacesSelectAndBtnBaseToConnect:disabled span {
    height: 42px !important;
    border-radius: 8px !important;
    margin-top: -2px !important;
}

.marketplacesSelectAndBtnBaseToConnect:disabled a {
    padding: 6px 0px 6px 11px;
    height: 42px;
    border-radius: 8px !important;
    margin-top: -2px !important;
}

.marketplacesSelectAndBtnBase.ant-select:not(.ant-select-customize-input)>.ant-select-selector {
    border: none;
}

.marketplacesSelectAndBtnBase.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select- customize-input)>.ant-select-selector {
    border: none;
    box-shadow: none;
    border-radius: 0px;
}

.marketplacesSelectAndBtnBase.ant-select:not(.ant-select-customize-input)>.ant-select-selector {
    background: none;
}

.marketplacesSelectAndBtnBase .ant-select-arrow .anticon>svg {
    fill: white;
}

.marketplacesSelectAndBtnBaseToConnect.ant-select:not(.ant-select-customize-input)>.ant-select-selector {
    border: none;
}

.marketplacesSelectAndBtnBaseToConnect.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select- customize-input)>.ant-select-selector {
    border: none;
    box-shadow: none;
    border-radius: 0px;
}

.marketplacesSelectAndBtnBaseToConnect.ant-select:not(.ant-select-customize-input)>.ant-select-selector {
    background: none;
}

.marketplacesSelectAndBtnBaseToConnect .ant-select-arrow .anticon>svg {
    fill: #010C33;
}

.marketplacesSelectAndBtnBaseToConnect .ant-select-arrow {
    margin-right: -15px !important;
    margin-top: -5px !important;
}

.marketplacesSelectAndBtnBaseToConnect .ant-select-selection-item {
    margin-right: 0px !important;
    margin-top: 0px !important;
}


.btnDownloadManual {
    background-color: white !important;
    border-color: #CACACA !important;
    color: #010C33 !important;
    text-align: center;
    border: none;
    box-shadow: none;
}

.btnDownloadManual:disabled {
    color: rgba(0, 0, 0, .25) !important;
    border-color: #d9d9d9 !important;
    background: #f5f5f5 !important;
    text-shadow: none;
    box-shadow: none;
    text-align: center;
}

.selectBg {
    background: #f5f5f5 !important;
}

.selectBg:hover {
    background: #f5f5f5 !important;
}

.btnDownloadManual.ant-btn-primary path {
    fill: #010C33 !important;
}

.ant-btn-primary[disabled],
.ant-btn-primary[disabled]:active,
.ant-btn-primary[disabled]:focus,
.ant-btn-primary[disabled]:hover {
    color: rgba(0, 0, 0, .25) !important;
    border-color: #d9d9d9 !important;
    background: #f5f5f5 !important;
    text-shadow: none;
    box-shadow: none;
}

a.ant-btn-disabled,
a.ant-btn-disabled:active,
a.ant-btn-disabled:focus,
a.ant-btn-disabled:hover {
    color: rgba(0, 0, 0, .25) !important;
    border-color: #d9d9d9 !important;
    background: #f5f5f5 !important;
    text-shadow: none;
    box-shadow: none;
}

.tooltipColumns {
    margin-left: 10px;
    margin-top: 8px;
}

.contentButtonsMarketplaces {
    display: flex;
    align-items: center;
    justify-content: center;
}

.titleModalMarketplaces {
    font-weight: 600;
    font-size: 20px;
    margin-bottom: 50px;
}

.marketplaceConnectedButton:hover {
    background-color: #F25154 !important;
}

.customWidth {
    width: 83% !important;
}

.mySellerAccountContainer {
    border: 1px solid #b7b7b733;
}

.customMarketplaceCollapse.ant-collapse.ant-collapse-icon-position-start {
    border: none !important;
    background-color: white !important;
}

.customMarketplaceCollapse > .ant-collapse-item > .ant-collapse-content.ant-collapse-content-active,
.customMarketplaceCollapse > .ant-collapse-item > .ant-collapse-content.ant-collapse-content-inactive {
    border: none !important;
    border-left: 2px solid #00e5a6 !important;
}

.customMarketplaceCollapse > .ant-collapse-item {
    border: none !important;
}

.customMarketplaceCollapse > .ant-collapse-header {
    border-left: 2px solid #00e5a6 !important;
}

.customMarketplaceCollapse div.ant-collapse-header[aria-expanded="true"] {
    border-left: 2px solid #00e5a6 !important;
    background-color: #00e5a61c !important;
}

.customMarketplaceCollapse div.ant-collapse-header[aria-expanded="false"] {
    border: none !important;
    border-bottom: 1px solid #b7b7b733 !important;
}

.customMediaQuery {
    margin-left: 2px !important;
}

@media screen and (max-width: 1537px) {
    .customMediaQuery {
        margin-left: 1px !important;
        width: 80% !important;
    }
}

@media screen and (min-width: 1537px) {
    .customMediaQuery {
        width: 80% !important;
        margin-left: 0px !important;
    }

}

@media screen and (min-width: 1650px) {
    .customMediaQuery {
        width: 80% !important;
        margin-left: 7px !important;
    }
}

.listMarketplaceRow {
    padding: 5px 0px;
    border-bottom: 1px solid #b7b7b733;
}

.customMediaQueryArticle {
    margin-left: 15px !important;
}

.newModalConnectInput {
    border-radius: 8px;
    padding: 10px;
}

a.marketplacesSelectAndBtnBaseToConnect {
    line-height: 40px !important;
}