.cardBaseStyle {
  width: 300px;
  padding: 12px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 30px;
}

.form-padding-top {
  padding-top: 15px;
}

.side-bar-margin-top {
  margin-top: 50px;
}

.cardBaseStyle div {
  padding: 0px;
}

.positionRadioContent {
  align-items: end;
  margin-left: 85%;
  text-align: end;
  position: absolute;
  top: 20px;
}

.ant-table-thead > tr > th {
  font-size: 0.8em;
}

.card-radio-inline {
  border-radius: 4px;
  display: inline-block;
  margin: 5px;
  width: 50%;
}

.positionRadioContent .ant-radio-checked .ant-radio-inner {
  height: 25px !important;
  width: 25px !important;
}
.positionRadioContent .ant-radio .ant-radio-inner {
  height: 25px !important;
  width: 25px !important;
}

.positionRadioContent .ant-radio-checked .ant-radio-inner:after {
  padding: 15px;
  position: relative;
  top: 4.5px;
  left: 4px;
  bottom: 0px;
  right: -10px;
}

.titleRadioContent {
  font-weight: 400;
  margin-bottom: 20px;
}

.cardRadioGroupContent {
  display: flex !important;
}

.infoIconNotifiy svg {
  fill: #010c33;
}

.title-content {
  font-size: 22px;
  padding-bottom: 16px;
  font-weight: 500;
}

.shipment-main-card {
  padding-top: 20px;
  padding-left: 60px;
  padding-right: 60px;
  border-radius: 8px;
}

.listing-shipment-table .ant-table-thead > tr > th {
  font-weight: 400;
  font-size: 13px;
}

.close-button svg {
  fill: rgba(32, 48, 60, 1);
}

.shipment-sticky-alert-message {
  background-color: rgba(240, 240, 240, 1);
  border-color: rgba(229, 229, 229, 1);
  height: 60px;
}

.save-draft-shipment {
  color: rgba(32, 48, 60, 1);
  border-color: rgba(32, 48, 60, 1);
  background-color: rgba(255, 255, 255, 1);
  border-radius: 4px;
  height: 40px;
}

.save-draft-shipment-svg svg {
  fill: rgba(32, 48, 60, 1);
}

.next-step-shipment svg {
  fill: rgba(255, 255, 255, 1);
}

.next-step-shipment {
  background-color: rgba(32, 48, 60, 1);
  color: rgba(255, 255, 255, 1);
  border-radius: 4px;
  height: 40px;
}

.detail-steps
  .ant-steps-item-finish
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot,
.detail-steps
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail:after,
.detail-steps
  .ant-steps-item-process
  .ant-steps-item-icon
  > .ant-steps-icon
  .ant-steps-icon-dot,
.detail-steps
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-tail:after {
  background-color: #05df9f;
}

.detail-steps
  .ant-steps-item.ant-steps-item-wait
  .ant-steps-item-title
  .ant-typography {
  color: rgba(184, 184, 184, 1);
}

#contents {
  margin-top: 18px;
}

#warning-mixed-box {
  margin-top: 10px;
  margin-bottom: 18px;
}

.boxes-shipment {
  margin-top: 10px;
}

.collapse-header {
  border-left-width: 2px;
}

.boxes-shipment .ant-collapse-expand-icon,
.collapse-header .ant-collapse-expand-icon {
  height: auto;
  margin-top: 5px;
}

.boxes-shipment .ant-collapse-expand-icon svg,
.collapse-header .ant-collapse-expand-icon svg {
  fill: rgba(32, 48, 60, 1);
}

.revision-restock .ant-collapse-content > .ant-collapse-content-box {
  background-color: #fafafa;
}

.revision-restock .ant-collapse > .ant-collapse-item:last-child,
.revision-restock .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
  background-color: #ffffff;
}

.revision-restock {
  border-radius: 4px;
}

.revision-restock .ant-collapse-expand-icon svg {
  fill: rgba(32, 48, 60, 1);
}

.revision-restock .ant-collapse-expand-icon {
  height: auto;
  margin-top: 5px;
}

.icon-download-color .anticon svg {
  fill: #1677ff;
}

.icon-color .anticon svg {
  fill: #20303c;
}

.avatar-picture {
  width: 56px;
  height: 56px;
  margin-right: 12;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 5px;
}

.grey-button svg {
  fill: #646464;
}
