#custom-icon-color-pdf svg {
  color: black;
  fill: black;
  font-size: 16px;
}

#custom-icon-color-download svg {
  color: rgba(15, 98, 254, 1);
  fill: rgba(15, 98, 254, 1);
  font-size: 16px;
}

.request-step-title {
  background: #f0f0f0;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  padding: 4px 16px;
}
