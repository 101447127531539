.container-shipments {
  background-color: #f9f9f9;
  padding-left: 50px;
  padding-right: 50px;
}

.container-shipments .ant-table-thead > tr > th {
  border-bottom: 1px solid #c4c4c4;
}

.container-shipments .ant-table-container table {
  border: none;
}

.container-shipments .ant-table-tbody > tr > td {
  border: none;
}

.container-shipments .ant-table-thead > tr > th {
  background: none;
  font-size: 14px;
  font-weight: 400;
}

.container-shipments .ant-table-container table > thead > tr th {
  padding: 8px 8px;
  text-align: center;
}
.container-shipments .ant-table-container table > tbody > tr > td {
  padding: 8px 8px;
  text-align: center;
}

.container-shipments .ant-select-selector {
  border-radius: 8px !important;
  background-color: #fff;
}

.container-shipments .ant-form-item-label > label {
  font-weight: 400 !important;
  font-size: 14px;
}

.filter-shipment-title {
  font-size: 12.6px;
  color: rgb(1, 12, 51);
  font-weight: 300;
  padding-bottom: 2px;
}

.shipment-type-tag {
  border-radius: 2px;
  border-color: #e5e5e5;
  background-color: #f0f0f0;
}

.shipment-type-tag svg {
  fill: #646464;
}

.shipment-status-tag {
  border-radius: 12px;
  border: 1px solid;
  padding-left: 10px;
  padding-right: 10px;
}

.shipment-actions .ant-btn.ant-btn-background-ghost[disabled] {
  border-color: transparent;
}

.shipment-actions svg {
  fill: #646464;
}

.shipment-actions .ant-btn.ant-btn-background-ghost[disabled] svg {
  fill: #e5e5e5;
}

.shipment-new-button {
  display: flex;
  justify-content: right;
  align-content: flex-end;
  flex-wrap: wrap;
}

.shipment-new-button .anticon.anticon-down svg {
  fill: rgb(255, 255, 255);
}

.shipment-new-button .anticon.anticon-plus svg {
  fill: rgb(255, 255, 255);
}
