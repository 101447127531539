.container-products {
  background-color: #f9f9f9;
  padding-left: 50px;
  padding-right: 50px;
  margin-top: 60px;
}

.container-products .ant-table-thead > tr > th {
  border-bottom: 1px solid #c4c4c4;
}

.container-products .ant-table-container table {
  border: none;
}

.container-products .ant-table-tbody > tr > td {
  border: none;
}

.container-products .ant-table-thead > tr > th {
  background: none;
  font-size: 14px;
  font-weight: 400;
}

.container-products .ant-table-container table > thead > tr th {
  padding: 8px 8px;
  text-align: center;
}
.container-products .ant-table-container table > tbody > tr > td {
  padding: 8px 8px;
  text-align: center;
}

.container-products .ant-select-selector {
  border-radius: 8px !important;
  background-color: #fff;
}

.container-products .ant-form-item-label > label {
  font-weight: 400 !important;
  font-size: 14px;
}

.importSpin.anticon svg {
  display: inline-block;
  fill: #fff;
}

.pillBox {
  background-color: #969696;
  color: #fff;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 32px;
}

.pillBox.withError {
  background-color: #f25154;
}

.pillBox.withoutError {
  background-color: #33ba6a;
}

.labelToInput:hover {
  background-color: #dbdbdb !important;
  cursor: text !important;
}

.labelToInput {
  border: 1px solid #cccccc;
  padding: 5px 10px !important;
}

.ant-typography.ant-typography-edit-content.labelToInput:hover {
  background-color: white !important;
}

.firstStepsCheckbox .checkboxSecondaryText {
  font-weight: 300;
  line-height: 24px;
  font-size: 12px;
  color: '#4F4F4F';
}

.publishProductsModal .link {
  text-decoration: underline;
  color: #00e5a6;
}

.publishProductsModal .buttonsContainer {
  display: flex;
  justify-content: space-between;
}

.publishProductsModal .leftButtonsContainer,
.publishProductsModal .rightButtonsContainer {
  position: relative;
  width: 45%;
}

.publishProductsModal .leftButton,
.publishProductsModal .rightButton {
  position: absolute;
  top: 30px;
}

.uploadProductsFile .ant-upload-select {
  width: 100%;
}

.upload-img-btn .ant-upload.ant-upload-select-picture-card,
.upload-img-btn .ant-upload-list-picture-card-container {
  margin-right: 24px;
  margin-bottom: 0;
  height: 109px;
}
