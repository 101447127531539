.shipment-tracking-modal-text {
  margin-bottom: 16px;
  margin-top: 16px;
  text-align: center;
}

.shipment-tracking-modal .ant-modal-content {
  border-radius: 10px;
}

.steps-shipment
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon
  .ant-steps-icon {
  color: #05df9f;
}

.steps-shipment
  .ant-steps-item-process
  > .ant-steps-item-container
  > .ant-steps-item-icon {
  background: #010c33;
  border-color: #010c33;
}

.steps-shipment .ant-steps-item-finish > .ant-steps-item-container,
.steps-shipment .ant-steps-item-process > .ant-steps-item-container,
.steps-shipment .ant-steps-item-wait > .ant-steps-item-container {
  min-height: 60px;
}

.steps-shipment
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-tail::after {
  background-color: #05df9f;
}

.steps-shipment
  .ant-steps-item-finish
  > .ant-steps-item-container
  > .ant-steps-item-icon {
  border-color: #05df9f;
}
