@media (min-width: 992px) {
    .ant-col-lg-11 {
        flex: 0 0 48%!important;
        max-width: 50%!important;
    }
}

.courierAvatarCard .ant-card-body {
    padding: 15px!important;
}

.courierAvatarImageCard .ant-card-body {
    padding: 0!important;
}

.courierConnected:hover, .courierConnected:focus {
    color: white!important;
}

.courierNotConnected:hover, .courierNotConnected:focus {
    color: black!important;
}

.ant-switch-checked {
    background-color: #33BA6A!important;
}
