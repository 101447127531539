.date-container {
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 20px 36px;
  margin-bottom: 20px;
  margin-top: 20px;
}

.selected-card {
  border: 1px solid #00e5a6;
}
