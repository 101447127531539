.grey-box {
    background-color: #010C330D;
    border-radius: 4px;
    padding: 10px 12px;
}

.title-grey-box {
    background-color: #010C330D;
    border-radius: 8px;
    padding: 4px 16px;
    margin-bottom: 10px !important;
}

.summary-title {
    font-size: 20px;
    margin-bottom: 32px;
}

.container-fedexsend {
    background-color: white;
    padding: 40px 20px 20px 32px;
    border-radius: 10px;
}