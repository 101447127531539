.assistant-container {
    width: 100%;

}

.chat-card {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    max-width: 100%;
    max-height: 630px;
    height: 100%
}

.chat-layout {
    width: 1006px;
}

.chat-layout .header {
    height: 99px;
    flex-shrink: 0;
    background: #FAFAFA;
    border-radius: 4px 4px 0 0;
    border: 1px solid #BFBFBF;
}

.title-container {
    display: flex;
    margin-top: 20px;
    gap: 25px;
}

.title-avatar {
    border-radius: 100px;
    background: #010C33;
}

.title-container .ant-typography {
    color: '#20303C';
    font-family: Poppins;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: 11px;
}

.content-container {
    background-color: #E9E8E8;
    border: 1px solid #BFBFBF;
    border-top: none;
    border-bottom: none;
}

.threads-container {
    background: #E9E8E8;
    border-right: 1px solid #BFBFBF;
    height: 400px;
    overflow-Y: auto;
}

.threads-menu {
    background: #E9E8E8
}

.threads-menu .ant-menu-item span {
    color: #000000D9;
}

.threads-menu .ant-menu-item-selected span {
    color: #1890FF;
}

.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
    background-color: #FAFAFA;
}

.ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child.ant-menu-title-content>span {
    color: green
}

.anticon-send svg {
    fill: #20303C;
}

.footer-container {
    border-radius: 0 0 4px 4px;
    border: 1px solid #BFBFBF;
}

.text-area-container {
    display: flex;
    flex-direction: column;
    justify-content: center
}

.text-area-container>textarea {
    margin-right: 15px;
    border-radius: 20px;
    border: 1px solid #20303C;
    height: 35;
    align-self: center;
}