.dashboardtable-ventas {
    font-family: 'Poppins';
}

.ant-table-tbody {
    font-size: 14px;
}

.ant-table-thead {
    font-size: 17px;
}
