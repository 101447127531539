.chartLineStyleContentSales h4, .chartLineStyleContentSales h3, .chartLineStyleContentSales h2 {
    color: #010c33;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1;
    text-align: start;
}

.home-ultima-actualizacíon-icon {
    background-color: #f3f3f3;
}

.home-ultima-actualizacíon-icon:hover {
    background-color: #f3f3f3;
}

.home-info {
    text-align: left;
    background-color: white;
}

.home-info>div{
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
    align-items: stretch;
}

.home-info-text {
    margin-left: 10px;
    font-weight: 300;
    font-size: 1em;
}

.ant-card {
    box-shadow: none;
}

.add-marketplace-button {
    margin-top: 15px;
}

.intro-card .ant-card-body{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: flex-end;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;
}
.anticon svg {
    fill: none;
}

.filter-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    margin-top:5px;
    -moz-box-shadow: 0 0 0.2em 0.2px #ededed;
    -webkit-box-shadow: 0 0 0.2em 0.2px #ededed;
    margin-bottom: 10px;
}

#formulario .ant-divider{
    margin-top:0;
}

#formulario .ant-divider a {
    font-size: .8em;
    font-weight: 300;
    color: #04dfa0;
}

.ant-card.ant-card-bordered.filtros-meses{
    margin-top:5px;
    -moz-box-shadow: 0 0 0.2em 0.2px #ededed;
    -webkit-box-shadow: 0 0 0.2em 0.2px #ededed;
    margin-bottom: 5px;
}

.ant-card.ant-card-bordered.filtros-meses .ant-card-body{
    padding:12px;
}

.ant-card.ant-card-bordered.filtros-meses .ant-card-body .ant-row{
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin-top: 10px; 
}

.anticon svg {
    display: inline-block;
    fill: #04dfa0;
}

#arrowVariantRed svg  {
    fill: #F25154 !important;
}

#arrowVariantGreen svg {
    fill: #33BA6A !important;
}

.menu-icon {
    display: inline-block;
    fill: #04dfa0;
    min-width: 14px;
    margin-right: 10px;
    font-size: 14px;
}

.intro-totales .ant-col.ant-col-8,.intro-totales-detalle .ant-col.ant-col-8{
    margin-top:5px;
}

.intro-totales .ant-card-body, .intro-totales-detalle .ant-card-body{
    padding:12px;
}

.intro-totales h4, .titulo h4, .bot-3-cards h4,.intro-totales-detalle h4{
    color: #010c33;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1;
    text-align: left;
}

.intro-totales h1{
    margin-top: 0.7em !important;
    font-style: normal;
    color: #010c33;
}

/* .ant-card.ant-card-bordered.filtros-meses .ant-card-body .ant-row button:hover{
    background-color: #00E9A0;
    color: #f3f3f3 !important;
} */

.hide{
    display:none;
    visibility: hidden;
    opacity: 0;
}

.user-actions{
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
    align-content: stretch;
    width: 50%;
    font-weight: 300;
    font-size: 1em;
}

.ant-row.user-messges {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
}

.cardTableDashboard .ant-table-tbody > tr > td,
.cardTableDashboard .ant-table-tbody > tr > th,
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td{
    padding: 5px;
    overflow-X: hidden;
}

.cardTableDashboard .ant-table-container table > thead > tr:first-child th:first-child,
.cardTableDashboard .ant-table-container table > tbody > tr> td:first-child,
.cardTableDashboard .ant-table-container table > thead > tr:first-child th:last-child{
    padding:5px;
    text-align: center;
}

.cardTableDashboard .ant-table-container table > tbody > tr> td:last-child{
    text-align: right;
}

.bot-3-cards{
    margin-top:5px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: space-between;
    align-items: flex-start;
}

.bot-3-cards canvas{
    min-height:150px;
}

.ventas-home-listing-card{
    margin:0px 0px 0px 20px;
}

.intro-totales-detalle{
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: space-between;
    align-items: flex-start;
    min-height: 210px;
    width: 100%;
}

.intro-totales-detalle canvas{
    min-height:150px;
}

.detalle-table {
    margin-top: 10px;
}

.detalle-table table{
    table-layout: auto !important;
    width:100% !important;
    height: 50px;
    font-size: 17px;
}

.detalle-table .ant-table-body{
    /*overflow: hidden !important;*/
}

.detalle-table table>thead>tr>th:first-child,.detalle-table table>tbody>tr>td:first-child{
    width:40%;
}

.detalle-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-header > table > thead > tr > th,
.detalle-table .ant-table.ant-table-bordered > .ant-table-container > .ant-table-body > table > tbody > tr > td{
    border-right: 0;
}

.intro-totales-detalle>div:last-child{
    width:24%;
}

.anticon.anticon-frown.error svg {
    fill: red;
}

.anticon.anticon-frown.success svg {
    fill: #04dfa0;
}

.anticon.anticon-warning.warning svg {
    fill: #e18f0b;
}

.welcome-container{
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
}

.welcome-container .welcome-button {
    font-style: italic;
    font-family: 'Poppins';
    color: #00E5A6;
    font-size: 10px;
    background-color: #010C33;
}

.contentCard {
    border: none !important;
    border-radius: 10px;
}

.filtros-detalles {
    margin-bottom: 20px;
}

.topMargin-header {
    margin-top: 15px;
    font-weight: 400;
    display: flex;
    justify-content: center;
}

.firstLine-header {
    font-size: 10px;
    font-weight: 400;
}

.secondLine-header {
    font-weight: 400;
}

.onboardingModal{
    display: flex;
    justify-content: space-between;
}

.onboardingButtonIncomplete, .ant-btn.onboardingButtonIncomplete:disabled {
    height: 120px;
    width: 195px;
    background-color: #010C33;
    font-size: 20px;
    color: #FFFFFF;
    font-weight: 700;
    z-index: 2;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 10px;
}

.onboardingButtonComplete, .ant-btn.onboardingButtonComplete:disabled {
    height: 120px;
    width: 195px;
    background-color: #00E5A6;
    border-color: #00E5A6;
    font-size: 20px;
    color: #010C33;
    font-weight: 700;
    z-index: 2;
    padding-right: 10px;
    padding-left: 10px;
    border-radius: 10px;
}

.ant-btn.onboardingButtonIncomplete:focus, .ant-btn.onboardingButtonIncomplete:hover, .ant-btn.onboardingButtonComplete:focus,
.ant-btn.onboardingButtonComplete:hover {
    background-color: #00E5A6;
    border-color: #00E5A6;
    color: #010C33;
    z-index: 2;
}

.ant-btn.numberOnboarding:focus, .ant-btn.numberOnboarding:hover {
    color: #010C3333;
    z-index: 1;
}

.numberOnboarding {
    color: #FFFFFF66;
    z-index: 1;
    font-size: 70px;
    font-weight: 900;
    position: absolute;
    top: 0px;
}

.ant-btn.toggleOnboardingButton, .toggleOnboardingButton:hover {
    background-color: #D9D9D9;
    border-color: transparent;
    transition: none;
}

.ant-btn.closeOnboardingButton, .closeOnboardingButton:hover {
    background-color: #010C33;
    border-color: transparent;
    margin-left: 10px;
    transition: none;
}

.anticon.toggleOnboardingButtonIcon svg {
    fill: #010C33;
}

.anticon.closeOnboardingButtonIcon svg {
    fill: #E8E8E8;
}

.ant-row.warningRow {
    margin-top: 10px;
    margin-bottom: 20px;
    padding-left: 8px;
    padding-right: 8px;
}

.warningTitle {
    font-size: 23px;
    font-weight: 700;
    color: #010C33;
    text-align: center;
}

.warningText {
    font-size: 15px;
    line-height: 21px;
    font-weight: 600;
    text-align: center;
    color: #333333;
}

.warningButtons {
    display: flex;
    justify-content: space-around;
}

.cancelButton, .ant-btn.cancelButton:hover {
    font-style: normal;
    font-size: 12px;
    background-color: #E5E5E5;
    border-color: #E5E5E5;
    color: #010C33;
    font-weight: 500;
    width: 120px;
}

.continueButton, .ant-btn.continueButton:hover {
    font-style: normal;
    font-size: 12px;
    background-color: #010C33;
    border-color: #010C33;
    color: #FFFFFF;
    font-weight: 500;
    width: 120px;
}

.titlePages {
    font-size: 20px;
    color: #010C33;
    font-weight: 600;
    font-style: italic;
}
