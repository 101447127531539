.grey-box{
    background-color: #010C330D;
    border-radius: 4px;
    padding: 10px 12px;
}

.title-grey-box{
    background-color: #010C330D;
    border-radius: 8px;
    padding: 4px 16px;
    margin-bottom: 40px;
}

.summary-title{
    font-size: 20px;
    margin-bottom: 32px;
}

.container-fedexsend {
    background-color: white;
    padding:40px 20px 20px 32px;
    border-radius: 10px;
}

.titleNewBoxModal {
    font-weight: 600;
    font-size: 20px;
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }


  .saveNewBoxButton {
    height: 40px;
    border-radius: 4px;
    background-color: #20303C;
    border-color: #20303C;
    color: #FFFFFF;
  }
  
  .saveNewBoxButton:hover, .saveNewBoxButton:focus, .saveNewBoxButton:active {
    height: 40px;
    border-radius: 4px;
    border-color: #20303C;
    background-color: #20303C;
    color: #FFFFFF;
  }
  
  .saveNewBoxButton > span {
    color: #FFFFFF;
  }

  .saveNewBoxButtonIcon svg {
    fill: #FFFFFF;
  }

  .newBoxModal .ant-modal-content {
    border-radius: 10px;
  }