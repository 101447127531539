.line-class-drag {
    border-color: #010c33 !important;
}

.draggble {
    cursor: pointer;
}

.shipment-list {
    max-height: 355px;
    overflow-y: auto;
}


.ant-list.ant-list-sm.ant-list-split.ant-list-bordered.shipment-list::-webkit-scrollbar {
    width: 6px;
}

.ant-list.ant-list-sm.ant-list-split.ant-list-bordered.shipment-list::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.ant-list.ant-list-sm.ant-list-split.ant-list-bordered.shipment-list::-webkit-scrollbar-thumb {
    background-color: #cecece;
    border-radius: 8px;
}

.ant-list.ant-list-sm.ant-list-split.ant-list-bordered.shipment-list::-webkit-scrollbar-thumb:hover {
    background: #CACACA;
    border-radius: 8px;
}

.shipment-list-drag {
    display: flex;
    align-items: center;
    margin-right: -20px;
}

.shipment-list .ant-list-items {
    margin-bottom: 20px;
}

.draggble.selected div {
    color: rgba(0, 0, 0, 0.45) !important;
}

.draggble.selected h4 {
    color: black !important;
    font-style: normal !important;
}

.shipment-list-drag div:first-child {
    flex-grow: 1;
}

.ant-list-item.draggble.selected {
    background-color: white !important;
    border: 1px solid #00E5A6 !important;
}

.ant-list-item.draggble {
    border: 1px solid #d9d9d9 !important;
    border-radius: 5px !important;
}

.ant-list-bordered {
    border-radius: 10px !important;
}

.shipment-list .ant-list-item-meta {
    margin-left: 30px;
}

.radioButtonDraggableBorder {
    position: absolute;
    border: 1px solid #CACACA;
    padding: 2px;
    border-radius: 100%;
    background-color: white;
    left: 25px;
}

.ant-list-item.draggble.selected .radioButtonDraggableBorder {
    border: 1px solid #00E5A6 !important;
}

.radioButtonDraggable {
    position: relative;
    border: 1px solid #CACACA;
    padding: 6px;
    border-radius: 100%;
}

.ant-list-item.draggble.selected .radioButtonDraggable {
    transition: background-color .3s ease;
    background-color: #00E5A6 !important;
    border: 1px solid #00E5A6;
}

