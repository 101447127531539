.ant-skeleton-button.ant-skeleton-button-lg {
  width: 100% !important;
}

.ant-skeleton.ant-skeleton-element.ant-skeleton-active {
  width: 100% !important;
}

.ant-skeleton-image {
  border-radius: 10px !important;
}

.ant-input.customInputSkeleton {
  height: 35px !important;
  border-radius: 8px !important;
}

.ant-collapse>.ant-collapse-item>.ant-collapse-header {
  padding: 18px 16px;
}