.subscriptionsContainer {
    margin-top: 70px;
}

.subscriptionsContainer .billingInformationContainer {
    border-radius: 10px;
    margin: 4px;
    padding: 10px;
}

.subscriptionsContainer .checkoutContainer {
    border-radius: 10px;
    margin: 4px;
    padding: 10px;
}

