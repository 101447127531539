.login-container {
    height: 100vh;
}

.login-left {
    min-height: 100vh !important;
    width: 100%;
    background-image: url("../../../assets/login-background.png");
    -moz-background-size: 100% 100%; /*Firefox 3.6*/
    -o-background-size: 100% 100%; /*opera*/
    -webkit-background-size: 100% 100%; /*Safari*/
    background-size: 100% 100%; /*estandar css3*/
    -moz-border-image: url('../../../assets/login-background.png') 0; /*Firefox 3.5*/    
}

.login-hr-texts {
    width: 100%;
    display: inline-block;
}

.login-button-container.CheckboxRememberPassword {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    font-style: italic;
}

.ant-checkbox-wrapper.ant-checkbox-group-item {
    font-size: 14px;
    color: #010C33;
}

.login-content-inputs {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-content-texts {
    margin-top: 40%;
    padding-left: 15%;
    padding-right: 15%;
}

.login-logo {
    height: auto;
    width: 100%;
    max-width: 200px;
}

.login-email-item {
    font-style: italic;
    color: #010C33;
}

.login-password-item {
    font-style: italic;
    color: #010C33;
}

.ant-input.login-input-item {
    border-radius: 8px;
    font-size: 14px;
}

.ant-input-affix-wrapper.ant-input-password.login-input-item {
    border-radius: 8px;
    font-size: 14px;
}

.login-error {
    margin-bottom: 4px;
}

.request-password-error {
    margin-bottom: 10px;
    margin-top: 10px;
}

.reset-password-error {
    margin-bottom: 10px;
    margin-top: 10px;
}

.reset-password-item {
    margin-bottom: 4px;
    padding-top: 10px;
}

.login-logo-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.login-title {
    margin-top: 10px;
    font-size: 14pt;
}

.ant-typography.login-title-right {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 10px;
}

.login-subtitle-right {
    font-size: 16px;
    margin-bottom: 40px;
}

.login-divider {
    margin-top: 5px;
    margin-bottom: 10px;
}

.signup-button-container {
    margin-top: 20px;
}

.recoverPassword .ant-btn-link > span {
    color: #969696;
    border:  none;
    font-size: 14px;
    text-decoration: underline;
    font-style: italic;
}

.wrongCredentialsModal {
    text-align: center;
    font-family: 'Poppins';
    font-style: normal;
    font-size: 14px;
    line-height: 21px;
}

.wrongCredentialsTitle {
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    font-style: italic;
    text-align: center;
}

#wrongCredentialsButton {
    padding: 8px 30px;
    margin-top: 10px;
    height: auto;
}

.ant-btn.btn-primary-green.md-btn.login > span {
    font-style: italic;
    font-size: 14px !important;
    font-weight: 500;
}