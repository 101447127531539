@import '~antd/dist/antd.min.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

.w-400 {
  font-weight: 400;
}

.w-500 {
  font-weight: 500;
}

.w-600 {
  font-weight: 600;
}

.w-700 {
  font-weight: 700;
}

.menu-main {
  display: initial;
}

.menu-mobil {
  display: none;
}

span.anticon.anticon-tag.btn-primary.home-listing-title-icon,
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #010c33 !important;
}

.ant-typography.title-primary,
.title-primary {
  color: #010c33 !important;
}

.textNormalTableInventory h3 {
  font-style: normal !important;
  font-weight: 400;
}

.desplegarMenuIcon {
  color: 'white';
  background-color: '#010c33' !important;
  height: 50px;
  position: 'relative';
  top: 70px;
  left: 120px;
}

h1 {
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  font-style: italic;
}
h2 {
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  font-style: italic;
}
h3 {
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  font-style: italic;
}
h4 {
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  font-style: italic;
}
h5 {
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  font-style: italic;
}

.md-btn span {
  padding: 0px;
  font-size: 12px;
}

.lg-btn span {
  padding: 0px;
  font-size: 18px;
}

.ant-btn.btn-primary-darkBlue {
  background-color: #010c33;
  border-color: #010c33;
  color: white;
  margin: 10px;
  width: 110px;
  height: auto;
  border-radius: 6px;
}

.ant-btn.btn-primary-darkBlue:hover {
  background-color: #010c33;
  border-color: #00e5a6;
  color: #00e5a6;
  margin: 10px;
  border-radius: 6px;
}

.ant-btn.btn-secondary-white {
  background-color: white;
  color: #010c33;
  margin: 10px;
  width: 110px;
  height: auto;
  border-radius: 6px;
}

.ant-btn.btn-secondary-white:hover {
  background-color: white;
  border-color: #00e5a6;
  color: #00e5a6;
  margin: 10px;
  border-radius: 6px;
}

.ant-btn.btn-primary-green {
  border-color: #00e5a6;
  background-color: #00e5a6;
  color: #010c33;
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.ant-btn.btn-primary-green:focus {
  border-color: #00e5a6;
  background-color: #00e5a6;
  color: #010c33;
}

.ant-btn.btn-primary-green:disabled {
  border-color: #d9d9d9;
  background: #f5f5f5;
  color: rgba(0, 0, 0, 0.25);
}

.ant-btn.btn-primary-green:active,
.ant-btn.btn-primary-green:hover {
  color: #00e5a6;
  border-color: #00e5a6;
  background: #fff;
}

.ant-row-rtl .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.site-layout-background {
  background: #fff;
}

.ant-card,
.ant-table table {
  box-shadow: none;
}

.menuLateral {
  height: 100%;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #010c33;
  overflow-x: hidden;
  padding: 2em 0em 0em 0em;
  font-style: italic;
  font-weight: 300;
}

.menuLateral ul > li > ul {
  font-style: normal;
}

.menuLateral .anticon svg {
  fill: rgba(255, 255, 255, 0.65);
}

.contentMenuOptions > div > ul > li > span > a {
  padding: 0;
}

.menuLateralMobile {
  height: 100%;
  width: 250px;
  transition: all 0.5s;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #010c33;
  overflow-x: hidden;
  padding: 2em 0em 0em 0em;
}

.menuLateralMobileHidden {
  height: 100%;
  width: 0px;
  transition: all 0.5s;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  background-color: #010c33;
  overflow-x: hidden;
  padding: 2em 0em 0em 0em;
}

.menuLateral a {
  padding: 0.5em;
  text-decoration: none;
  color: #969696;
  display: block;
}

.menuLateral a:hover {
  color: white;
}

.menuLateral::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

.menuLateral::-webkit-scrollbar-track {
  background: #2f4756; /* color of the tracking area */
}

.menuLateral::-webkit-scrollbar-thumb {
  background-color: #16daa0; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid #2f4756; /* creates padding around scroll thumb */
}

.menuLateralMobile a {
  padding: 0.5em;
  text-decoration: none;
  color: #969696;
  display: block;
}

.menuLateralMobile a:hover {
  color: white;
}

.menuLateralMobile::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

.menuLateralMobile::-webkit-scrollbar-track {
  background: #2f4756; /* color of the tracking area */
}

.menuLateralMobile::-webkit-scrollbar-thumb {
  background-color: #16daa0; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid #2f4756; /* creates padding around scroll thumb */
}

.menuLateralMobileHidden a {
  padding: 0.5em;
  text-decoration: none;
  color: #969696;
  display: block;
}

.menuLateralMobileHidden a:hover {
  color: white;
}

.menuLateralMobileHidden::-webkit-scrollbar {
  width: 12px; /* width of the entire scrollbar */
}

.menuLateralMobileHidden::-webkit-scrollbar-track {
  background: #2f4756; /* color of the tracking area */
}

.menuLateralMobileHidden::-webkit-scrollbar-thumb {
  background-color: #16daa0; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid #2f4756; /* creates padding around scroll thumb */
}

.ant-menu-title-content {
  color: hsla(0, 0%, 100%, 0.65);
}

.contentMenuOptions .ant-menu-item.ant-menu-item-only-child {
  background: #010c33;
}

.contentMenuOptions .ant-menu.ant-menu-sub.ant-menu-inline {
  background: #010c33;
}

.contentMenuOptions
  .ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal)
  .ant-menu-item-selected {
  background: transparent;
}

.contentMenuOptions
  .ant-menu-item.ant-menu-item-selected.ant-menu-item-only-child {
  border-left: 5px solid;
}

.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon,
.ant-menu-dark .ant-menu-item-selected .ant-menu-item-icon + span,
.ant-menu-dark .ant-menu-item-selected .anticon,
.ant-menu-dark .ant-menu-item-selected .anticon + span,
.ant-menu-dark .ant-menu-item-selected > a,
.ant-menu-dark .ant-menu-item-selected > a:hover,
.ant-menu-dark .ant-menu-item-selected > span > a,
.ant-menu-dark .ant-menu-item-selected > span > a:hover {
  color: #00e5a6 !important;
}

.contentMenuOptions {
  padding: 10px;
  padding-right: 10px;
  background: #010c33;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.textMarketPlaceCountry {
  position: relative;
  bottom: 10px;
  left: 5px;
  font-size: 12px;
  margin-left: 2px;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.content-div {
  padding: 20px;
}

.public-layout {
  min-height: '100vh';
  background: #fff !important;
}

.login-layout {
  /* min-height: '100vh'; */
  background: #fff !important;
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  margin: auto;
}
/* colors */
.primary {
  color: #5365e3 !important;
}
.green {
  color: #1aae9f !important;
}
.darkBlue {
  color: #010c33 !important;
}
.gray {
  color: gray !important;
}
.orange {
  color: #e8833a !important;
}
/* background-colors */
.background-primary {
  background-color: #5365e3;
}
.background-green {
  background-color: #1aae9f !important;
}
.background-gray {
  background-color: gray;
}
/* widths */
.input-width-40-percent {
  width: 40% !important;
}
.input-width-90-px {
  width: 90px;
}
.input-width-100-percent {
  width: 100% !important;
}
/* margins */
.margin-left-200 {
  margin-left: 200px;
}
.margin-top-50 {
  margin-top: 50px;
}
.margin-left-40 {
  margin-left: 40px;
}
.margin-left-10 {
  margin-left: 10px;
}
.margin-left-5 {
  margin-left: 5px;
}
.margin-right-10 {
  margin-right: 10px;
}
.margin-right-20 {
  margin-right: 20px;
}
/* fonts */
.font-size-20 {
  font-size: 20px;
}
.font-size-14 {
  font-size: 14px;
}
/* float */
.float-right {
  float: right;
}
/* Title primary */
.ant-typography.title-primary,
.title-primary {
  color: #5365e3;
}
/* Text color gray */
.text-color-gray {
  color: gray;
}
/* Antd hover and select color */

/* Align text card */
.ant-card-body > h3,
.ant-card-body > p {
  text-align: initial;
}
/* Antd button type link */
.btn-primary {
  color: #5365e3 !important;
}
.btn-primary-margin {
  color: #5365e3 !important;
  margin: 0 8px;
}
.btn-link {
  color: #5365e3 !important;
  height: 0px !important;
  padding: 0px 0px 0px 5px;
  border: none !important;
}
.btn-link:hover,
.btn-link:focus {
  color: #5365e3 !important;
  border: none !important;
  font-weight: 600;
}
.btn-link:focus {
  color: #5365e3 !important;
  border: none !important;
  font-weight: 600;
}
.btn-link-empty {
  background-color: #f5f5f5 !important;
  color: #b8b8b8 !important;
  border: none !important;
}
.btn-link-filled {
  background-color: #010c33 !important;
  color: #fff !important;
  border: none !important;
}
.btn-link-filled-disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  cursor: not-allowed;
}
.btn-link-filled-margin {
  margin: 0 8px;
  margin-top: 20px;
  background-color: #010c33 !important;
  color: #fff !important;
  border: none !important;
}

.btn-primary.ms-1 {
  margin-left: 5px;
}

.ant-col-24.flex-margin-top {
  display: flex;
  align-content: flex-end;
  flex-wrap: nowrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 1rem;
}

.justifiContentAttachments {
  text-align: left;
  justify-content: left;
}

.ant-tabs-tab:hover {
  color: #000 !important;
}
.ant-tabs-tab:active {
  color: #000 !important;
}

.ant-tabs-ink-bar {
  background: #010c33 !important;
}

.spanTitleAttachments {
  color: #00e9a0;
  font-weight: 500;
}

.spanTextAlertAttachments {
  color: red;
}

.btn-basic-green {
  background-color: #010c33 !important;
  color: #fff !important;
  border: none !important;
}

.btn-basic-green[disabled] {
  color: rgba(0, 0, 0, 0.25) !important;
  border-color: #d9d9d9 !important;
  background: #f5f5f5 !important;
}

.ant-progress-bg {
  background-color: #010c33 !important;
}

.ant-tabs-tab.ant-tabs-tab-active {
  border-bottom: solid #010c33 !important;
  z-index: 2;
}

.btn-basic-white {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #d9d9d9 !important;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
}

.btn-basic-white:hover {
  background-color: #fff !important;
  color: #000 !important;
  border: 1px solid #000 !important;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
}

.btn-link-filled-padding {
  margin: 0 8px;
  background-color: #010c33 !important;
  color: #fff !important;
  border: none !important;
}
.btn-link-filled:hover,
.btn-link-filled:focus {
  background-color: #010c33 !important;
  color: #fff !important;
  border: none !important;
  font-weight: 600;
}
.btn-link-filled:focus {
  background-color: #010c33 !important;
  color: #fff !important;
  border: none !important;
  font-weight: 600;
}
/* Empty circle icon */
.dot {
  height: 10px;
  width: 10px;
  border: 1px solid red;
  color: red;
  background-color: 'none';
  border-radius: 50%;
  display: inline-block;
  margin-right: 4px;
}
.dot-green {
  display: inline-block;
  margin-right: 4px;
  color: #1aae9f !important;
}
.dot-red {
  display: inline-block;
  margin-right: 4px;
  color: #ff0000 !important;
}
.dot-gray {
  height: 10px;
  width: 10px;
  border: 1px solid gray;
  color: gray;
  background-color: 'none';
  border-radius: 50%;
  display: block;
  float: right;
  margin-top: 14px;
}

/* Credit Card input */
.bmOFCG {
  display: inline-block;
  border: 1px solid #d9d9d9;
  width: 100%;
}
.cNiGdI {
  width: 100%;
}

/* sider */
.ant-layout-sider {
  background: none;
}
/* Modal Stock */
.listing-details {
  row-gap: 0px;
  padding: 5px 5px 5px 5px;
  border-bottom-width: 1px;
  border-bottom-color: #6f7d89;
  border-bottom-style: solid;
}

.ant-input:hover {
  border-color: #5365e3 !important;
}

.ant-input:focus {
  border-color: #5365e3 !important;
}

.generic-spinner {
  display: flex;
  justify-content: center;
}

.generic-spinner-padding {
  padding: 50px 50px 50px 50px;
}

.ant-spin-dot-item {
  background-color: #5365e3 !important;
}

.ant-select-selector:hover {
  border-color: #5365e3 !important;
}

.ant-select-selector:focus {
  border-color: #5365e3 !important;
}

/* spin  */
.spin-loading-outlined {
  font-size: 20px;
  color: #fff !important;
}

.spin-inside-button {
  margin: 0px 8px 0px 8px !important;
}

/* con return side bar */
.side-bar-icon-back {
  font-size: 17px;
  color: #4d5e6d !important;
  display: 'initial';
  margin-right: 30px;
}

/* sidebar */
.text-align-left {
  text-align: left;
}
.text-align-right {
  text-align: right;
}
.text-align-left-margin-top {
  text-align: left;
  margin-top: 20px;
}

/* page: my Account */
.padding-layout {
  padding: 0 24px 24px;
}
.padding-layout-content {
  padding: 24px;
  margin: 0;
}

/* page: Orders  */

/* .order-table .ant-table-content {
  min-width: 900px;
} */

.order-table .ant-table-cell {
  padding: 4px;
}

.order-table .ant-table-thead .ant-table-cell {
  color: grey;
  border-bottom: 2px solid #8b8b8b;
}
.order-table-shipping-row {
  background-color: #e9ecf1;
}

.order-table-shipping-row .ant-table-selection-column {
  display: none;
}

.order-table-order-row .indent-level-1 {
  display: none;
}

.order-table-order-row .ant-table-cell-with-append {
  padding-left: 0px;
}

.order-table-shipping-row .ant-table-selection-column {
  display: none;
}

.order-table-product-row {
  color: grey;
  background-color: rgb(238, 237, 237);
}

.order-table-product-row:hover {
  background-color: rgb(238, 237, 237);
}

.order-table-product-row .ant-table-selection-column {
  display: none;
}

.text-capitalize {
  text-transform: capitalize;
}

.order-form-table .ant-form-item {
  margin-bottom: 0;
}

body {
  font-family: 'Poppins' !important;
}

.utils-documents .ant-row {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 20px;
}

.welcome-bar .ant-row {
  text-align: left;
  padding-left: 40px;
  padding-right: 30px;
}

.welcome-bar button {
  margin-right: 30px;
}

/***IMAGEN CORPORATIVA****/

.logo img.logo {
  filter: brightness(0) invert(1);
}

.ant-alert-warning svg path {
  color: #faad14 !important;
}

span.anticon.anticon-tag.btn-primary.home-listing-title-icon,
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: yellow;
}

.dot-red path {
  color: red;
  fill: red;
}

.ant-layout {
  background-color: #fff !important;
}

.cardTableDashboard .ant-card-body {
  padding-left: 10px;
  padding-right: 10px;
}

.ant-btn-primary path {
  fill: #00e5a6;
}

.ant-card,
.ant-table table {
  border: 0.5px solid #a3a3a336;
}

.ant-btn-background-ghost.ant-btn-primary,
.ant-pagination-item-active {
  border-color: #010c33 !important;
}

.ant-btn:hover {
  border-color: #00e5a6;
  color: #00e5a6;
}

.home-support-form-button,
.ant-typography.title-primary,
.ant-menu-item-selected {
  color: #00e5a6 !important;
}

.ant-row.welcome-bar {
  box-shadow: 0.871px 2.659px 2px 0px rgb(0 0 0 / 13%);
  border: 1px solid #cfcfcf;
  padding: 1.5rem;
  margin-top: -40px;
}

.ant-collapse-header {
  font-weight: 300;
}

span.anticon.anticon-tag.btn-primary.home-listing-title-icon path {
  fill: #00e9a0;
}

.ant-modal.buttonInfo button:first-of-type {
  display: none;
}

.ant-modal.buttonInfo p {
  padding: 1rem;
  text-align: justify;
}

.ant-page-header-heading-left {
  display: flex !important;
  align-items: flex-start !important;
  margin: 0;
  overflow: auto !important;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
}

.tip-password {
  font-size: 0.8rem;
  color: #00cfa0;
}

.react-resizable {
  position: relative;
  background-clip: padding-box;
}

.react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  z-index: 1;
  width: 10px;
  height: 100%;
  cursor: col-resize;
}

.dataStudio iframe {
  width: 100%;
  border: 0;
  height: 100vh;
}

.dataStudio {
  margin-top: 0;
}

ul.ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light {
  margin: -1px auto;
  padding-left: 12%;
}

.resume .resume-listing ul {
  list-style: none;
  padding-left: 0;
  width: 30%;
}

.resume .resume-listing ul li {
  padding-left: 0;
  margin-left: 0;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: flex-start;
}

.resume .resume-listing ul li svg {
  margin-top: 4px;
  margin-right: 4px;
}

.resume .resume-listing ul .title-status {
  width: 50%;
}

.phone-code {
  width: 40%;
}

.home-status-container {
  border-left: 11px solid #16daa0;
}

.home-status-listing {
  border-left: 2px dotted #16daa0;
}

.home-status-container span {
  font-size: 1.1em;
  font-weight: 400;
}

.home-welcome-text {
  font-size: 1.3em;
}

.home-status-container .ant-spin-nested-loading {
  margin-top: 2%;
  padding-bottom: 5%;
}

.home-status-listing span.anticon.anticon-unordered-list {
  margin-right: 1%;
  margin-top: 1.5%;
}

.home > .ant-row:not(:first-child) {
  background-color: #ededed;
}

.ant-row.welcome-bar button {
  position: absolute;
  bottom: 0;
  margin: 0;
  right: 15px;
  height: 50px;
}
.ant-btn-background-ghost.ant-btn-primary.resources {
  border: 1px solid rgb(0 0 0 / 13%) !important;
  box-shadow: 0.871px 2.659px 2px 0px rgb(0 0 0 / 13%);
  color: #16daa0 !important;
  background-color: #fff !important;
}

.utils-documents .ant-list-split .ant-list-item:last-child {
  box-shadow: 0.871px 2.659px 2px 0px rgb(0 0 0 / 13%);
  border: 1px solid #cfcfcf;
}

.utils-documents .ant-list-item-meta-title > a {
  display: inline-block;
  width: 100%;
}

.floating-menu {
  position: fixed;
  z-index: 2;
  bottom: 0;
  text-align: center;
  width: 100%;
  left: 0;
}

.floating-menu ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-around;
  justify-content: center;
  align-items: center;
}

.floating-menu li {
  padding-right: 15px;
  padding-left: 15px;
  background-color: #fff;
  border: 1px solid #e7e7e7;
  box-shadow: 0.871px 2.659px 2px 0px rgb(0 0 0 / 13%);
  font-size: 1.3em;
  font-weight: 300;
  min-width: 100px;
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  min-height: 6vh;
  padding-top: 1.5vh;
}

.floating-menu .anticon svg,
.floating-menu .anticon path {
  fill: #00e9a0;
  color: #00e9a0;
}

.floating-menu li:hover {
  background-color: #00e5a6;
  color: #fff;
}

.floating-menu li:hover svg,
.floating-menu li:hover path {
  fill: #fff;
}

@media screen and (max-width: 700px) {
  .login-left {
    display: none;
  }

  .login-content-inputs {
    padding-left: 20% !important;
    padding-right: 20% !important;
  }

  .menu-main {
    display: none;
  }

  .menu-mobil {
    display: initial;
    padding: 0px;
    height: 100px;
  }

  .contentMenuOptions {
    padding: 10px;
    padding-right: 10px;
  }

  .btnMenuMbole {
    border: none;
    color: #00e5a6;
    font-size: 30px;
    margin-top: 4px;
    background-color: #010c33;
  }

  .ant-layout-header {
    background-color: #010c33;
  }

  h4.ant-typography,
  .ant-typography h4 {
    color: white;
  }

  .ant-avatar.ant-avatar-icon > .anticon {
    margin-top: 10px;
  }

  span.anticon.anticon-align-left.btnMenuMbole {
    width: 80px;
    height: 100px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-content: center;
    justify-content: center;
    align-items: center;
    z-index: 1;
    position: fixed;
    right: 0 !important;
    left: auto !important;
    top: -4px !important;
  }
}

.documents .ant-modal {
  width: 100% !important;
  padding-left: 258px;
  padding-right: 8px;
  transform-origin: 976.5px 169px !important;
  top: 105px;
  height: 85vh;
}

.documents .ant-modal-content,
.ant-modal-body iframe {
  height: 100%;
  width: 100%;
}

.documents .ant-modal-body {
  height: 85%;
}

.content-div {
  font-weight: 300 !important;
  font-size: 1em !important;
}

.ant-btn,
.ant-select-multiple .ant-select-selection-item-content,
.ant-form-vertical .ant-form-item-label > label,
.ant-picker-input > input {
  font-weight: 300;
  font-size: 0.9em;
}

ul.ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light.topmenu {
  background-color: #fff !important;
  padding: 0;
  margin: 0;
  justify-content: flex-end;
  width: auto;
  min-width: 210px;
  z-index: 1;
}

.initialContent {
  background: #f9f9f9;
  padding: 80px 50px 50px 50px;
}

.filterCurrency {
  margin-left: auto !important;
  font-size: 13px !important;
}

.filterCurrency
  > .and-select-focused:not(.and-select-disabled).and-select:not(
    .and-select-customize-input
  )
  .and-select-selector {
  box-shadow: none !important;
}

.filterCurrency > .ant-select-selector {
  border: none !important;
  box-shadow: none !important;
}

.notifications-body .ant-drawer-body {
  padding: 0;
  background-color: #f9f9f9;
}

.empty-notifications {
  font-size: 35px;
}

.empty-notifications.anticon svg {
  fill: #9C9C9C;
}

.ant-list-item.notifications-item {
  padding: 12px 16px;
}

.notifications-item .ant-list-item-meta-description {
  width: '100%';
}

.notifications-item.read {
  background-color: #f5f5f5;
}

.notifications-item.unread {
  background-color: white;
}

.notification-avatar.ant-avatar.ant-avatar-square.ant-avatar-image {
  border: 1px solid rgba(0, 0, 0, .2);
  background-color: white;
  border-radius: 5px;
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.notification-close .anticon > svg {
  fill: #f00820ae;
}

.notification-timeago {
  font-size: 12px;
  color: #646464;
  font-style: italic;
}

.topmenu.ant-menu-horizontal > .ant-menu-submenu::after {
  display: none;
}

.ant-menu-horizontal > .ant-menu-submenu a > span {
  color: #969696;
  font-size: 12px;
  margin-right: 10px;
}

.topmenu .anticon svg {
  fill: #191919;
  font-size: 12px;
}

.topmenuContainer {
  position: fixed;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e9e9e9;
  z-index: 10;
  background-color: #fff;
  font-family: 'Poppins';
  font-size: 10px;
}

.topmenuContainer > nav {
  padding-left: 2rem;
  font-size: 12px;
}

@media only screen and (max-width: 700px) {
  ul.ant-menu-overflow.ant-menu.ant-menu-root.ant-menu-horizontal.ant-menu-light.topmenu {
    display: none;
  }
}
.ant-input-number-input {
  text-align: center;
}

.tooltipVerticalScroll {
  max-height: 300px;
  overflow-y: auto;
}

.width100 {
  width: 100%;
}

.ant-col .column-title,
.column-title {
  display: flex;
  align-items: center;
  max-width: calc(100% - 24px - 10px - 8px);
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 8px;
}

.login-content-inputs .ant-spin-container,
.register-content-inputs .ant-spin-container {
  width: 340px;
}

.__floater__arrow, .react-joyride__tooltip {
  margin: 10px;
}

.loading-animation {
  display:inline-block;
  font-family: monospace;
  color: #000;
  clip-path: inset(0 3ch 0 0);
  animation: loadingAnimation 1s steps(4) infinite;
}

@keyframes loadingAnimation {
  to {
    clip-path: inset(0 -1ch 0 0)
  }
}
