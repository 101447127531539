.listing .ant-checkbox{
    display: none;
}

.input-form-margin-bottom{
    margin-bottom: 0 !important;
    border-radius: 8;
}

.contentIcon svg {
    fill: #010C33;
}

.ant-table-header.ant-table-sticky-holder {
    top: 57px !important
}

.tooltipDatePicker svg {
    position: absolute;
    right: 30px;
    bottom: 10px;
    fill: orange
}
