.register-container {
    height: 100vh;
}

.register-layout {
    height: 100%;
}

.register-left {
    min-height: 100vh !important;
    width: 100%;
    background-image: url("../../../assets/login-background.png");
    -moz-background-size: 100% 100%; /*Firefox 3.6*/
    -o-background-size: 100% 100%; /*opera*/
    -webkit-background-size: 100% 100%; /*Safari*/
    background-size: 100% 100%; /*estandar css3*/
    -moz-border-image: url('../../../assets/login-background.png') 0; /*Firefox 3.5*/    
}

.register-hr-texts {
    width: 100%;
    display: inline-block;
}

.register-content-inputs {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.register-content-texts {
    margin-top: 40%;
    padding-left: 15%;
    padding-right: 15%;
}

.register-logo {
    height: auto;
    width: 100%;
    max-width: 200px;
}

.register-error {
    margin-bottom: 8px;
}

.register-logo-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.register-title {
    margin-top: 10px;
    font-size: 14pt;
    margin-bottom: 20px;
    display: block;
}

.ant-typography.register-title-right {
    font-size: 28px;
    font-weight: 700;
    margin-bottom: 10px;
}

.register-subtitle {
    font-size: 16px;
    margin-bottom: 30px;
}

.register-divider {
    margin-top: 5px;
    margin-bottom: 10px;
}

.register-button-container {
    margin-top: 8px;
}

.space {
    padding-bottom: 32px;
}

.recoverPassword, .ant-btn-link {
    color: #969696;
    border:  none;
    font-size: 13px;
}

.character {
    font-size: 15px;
    border: 1px solid #E0E0E0;
    border-radius: 8px;
    background-color: #FFFFFF;
}

.ant-form-item-required {
    font-style: italic;
    color: #010C33;
}

.ant-input.register-form-item, .ant-input-affix-wrapper.ant-input-password.register-form-item {
    border-radius: 8px;
}

.ant-btn.btn-primary-green.md-btn.login > span {
    font-style: italic;
    font-size: 14px !important;
    font-weight: 500;
}

.ant-btn.btn-primary-green.md-btn.register > span {
    font-style: italic;
    font-size: 14px !important;
    font-weight: 500;
}